(function(){
	'use strict';

	angular.module('ctp.scrollThenFix', [])
		.directive('setClassAtTop', ['$window', function ($window) {
			var $win = angular.element($window);		
			return {
				restrict: 'A',
				link: function (scope, element, attrs) {
					var topClass = attrs.setClassAtTop;
					var offsetTop = element.offset().top;
					$win.on('scroll', function (e) {
						var atTop = ($win.scrollTop() >= offsetTop);
						var height = element.height() + "px";
						element[atTop ? 'addClass' : 'removeClass'](topClass);
						element.next().css("margin-top", atTop ? height : "0px");
					});
				}
			};
		}]);
})();
